<template>
  <div>
    <div class="fixed bottom-8 right-4 z-50">
      <VMenu>
        <a class="text-sm" target="_blank" href="https://wa.me/+62818881388" rel="nofollow">
          <button type="button" class="bg-green-700 hover:bg-green-900 text-white py-2 px-4 rounded-full font-normal">
            {{ $t('customer_care_text') }}
          </button>
        </a>
        <!-- <template #popper>
          <ul class="menu">
            <li>
              <a class="text-sm" target="_blank" href="https://wa.me/+62818881388" rel="nofollow">
                <Icon name="i-logos-whatsapp-icon" />
                +62 818-881-388
              </a>
            </li>
            <li>
              <a class="text-sm" href="mailto:reservation@suryabintang.com" target="_blank">
                <Icon name="i-heroicons-envelope" />
                reservation@suryabintang.com
              </a>
            </li>
          </ul>
        </template> -->
      </VMenu>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
